import React, { useState, useMemo } from 'react';
import Modal from 'react-modal';
import ProductGrid from './ProductGrid';
import CloseButton from './CloseButton';
import FavoritesTabs from './FavoritesTabs';
import useFavorites from '../hooks/useFavorites';
import '../styles/components/FavoritesModal.css';

Modal.setAppElement('#root');

function FavoritesModal({ isOpen, onClose }) {
  const { favorites, allTimeFavorites } = useFavorites();
  const [activeTab, setActiveTab] = useState('thisWeek');

  const sortedProducts = useMemo(
    () => ({
      thisWeek: [...favorites].sort(
        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
      ),
      allTime: [...allTimeFavorites].sort(
        (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
      ),
    }),
    [favorites, allTimeFavorites]
  );

  const currentProducts =
    sortedProducts[activeTab === 'thisWeek' ? 'thisWeek' : 'allTime'];
  const isEmpty = currentProducts.length === 0;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Favorites Modal"
      className="favorites-modal"
      overlayClassName="favorites-modal-overlay"
    >
      <div className="favorites-header">
        <FavoritesTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          favoritesCount={favorites.length}
          allTimeFavoritesCount={allTimeFavorites.length}
        />
        <CloseButton onClick={onClose} />
      </div>

      <div className="favorites-content">
        {isEmpty ? (
          <p className="no-favorites">
            {`You haven't added any ${
              activeTab === 'thisWeek'
                ? 'favorites for this week'
                : 'all-time favorites'
            } yet.`}
          </p>
        ) : (
          <ProductGrid products={currentProducts} />
        )}
      </div>
    </Modal>
  );
}

export default FavoritesModal;
