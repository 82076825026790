import React, { useContext, useState, memo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Heart, ChartLine } from 'phosphor-react';
import { FavoritesContext } from '../context/FavoritesContext';
import PriceHistoryModal from './PriceHistoryModal';
import wooliesLogo from '../assets/woolies.png';
import colesLogo from '../assets/coles.png';
import { isFromLastWeek } from '../utils/dateUtils';
import '../styles/components/ProductCard.css';

const ProductCard = memo(({ product }) => {
  const { toggleFavorite, isFavorite } = useContext(FavoritesContext);
  const [showPriceHistory, setShowPriceHistory] = useState(false);

  const getBrandLogo = (brand) => {
    switch (brand.toLowerCase()) {
      case 'woolies':
        return { src: wooliesLogo, alt: 'Woolworths' };
      case 'coles':
        return { src: colesLogo, alt: 'Coles' };
      default:
        return null;
    }
  };

  const calculateSavings = () => {
    const originalPrice = product.originalPrice;
    const savings = product.savings;
    const savingsPercentage = (savings / originalPrice) * 100;
    const isHalfPrice = savingsPercentage >= 49.5;
    const isCurrentWeek = !isFromLastWeek(product.dateAdded);
    return {
      amount: savings.toFixed(2),
      percentage: savingsPercentage.toFixed(0),
      // Only true if product is both half price and from current week
      isHalfPrice: isHalfPrice && isCurrentWeek,
      showOriginalPrice: !isHalfPrice || !isCurrentWeek,
    };
  };

  const savings = calculateSavings();

  return (
    <>
      <HelmetProvider>
        <meta
          name="description"
          content={`${product.name} on sale at ${product.brand}. Save ${savings.percentage}% now!`}
        />
      </HelmetProvider>
      <div className="product-card" data-half-price={savings.isHalfPrice}>
        <div className="product-image-container">
          {/* Display savings badge only for half price items */}
          {savings.isHalfPrice && (
            <div className="savings-badge">
              <span>-{savings.percentage}%</span>
            </div>
          )}
          {/* Product image with store link */}
          <a href={product.link} target="_blank" rel="noopener noreferrer">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
              loading="lazy"
            />
            <img
              src={getBrandLogo(product.brand).src}
              alt={getBrandLogo(product.brand).alt}
              className="brand-logo"
              loading="lazy"
            />
          </a>
        </div>
        <div className="product-info-container">
          <div className="product-name">{product.name}</div>
          <div className="price-container">
            {/* Show sale price for half price items, original price otherwise */}
            <p className="current-price">
              $
              {savings.isHalfPrice
                ? product.price.toFixed(2)
                : product.originalPrice.toFixed(2)}
            </p>
            {/* Show original price for reference when applicable */}
            {!savings.showOriginalPrice && (
              <p className="original-price">
                ${product.originalPrice.toFixed(2)}
              </p>
            )}
          </div>
          <div className="price-and-actions-container">
            {product.pricePerUnit && (
              <p className="price-per-unit">{product.pricePerUnit}</p>
            )}
            <div className="product-actions">
              <button
                className="price-history-button"
                onClick={() => setShowPriceHistory(true)}
              >
                <ChartLine size={24} />
              </button>
              <button
                className="favorite-button"
                onClick={() => toggleFavorite(product)}
              >
                <Heart
                  size={24}
                  weight={isFavorite(product._id) ? 'fill' : 'regular'}
                  className={
                    isFavorite(product._id)
                      ? 'heart-icon filled'
                      : 'heart-icon regular'
                  }
                />
              </button>
            </div>
          </div>
          {showPriceHistory && (
            <PriceHistoryModal
              product={product}
              onClose={() => setShowPriceHistory(false)}
            />
          )}
        </div>
      </div>
    </>
  );
});

export default ProductCard;
