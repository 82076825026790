import React from 'react';
import '../styles/components/FavoritesTabs.css';

function FavoritesTabs({
  activeTab,
  setActiveTab,
  favoritesCount,
  allTimeFavoritesCount,
}) {
  return (
    <h2 className="favorites-tabs">
      <button
        className={`favorites-tab ${activeTab === 'thisWeek' ? 'active' : ''}`}
        onClick={() => setActiveTab('thisWeek')}
        aria-label="View this week's favorites"
      >
        This Week's Favorites
        <span className="tab-count">{favoritesCount}</span>
      </button>
      <button
        className={`favorites-tab ${activeTab === 'allTime' ? 'active' : ''}`}
        onClick={() => setActiveTab('allTime')}
        aria-label="View all-time favorites"
      >
        All-Time Favorites
        <span className="tab-count">{allTimeFavoritesCount}</span>
      </button>
    </h2>
  );
}

export default FavoritesTabs;
